import './App.css';
import { Blobs } from './blobs'
import { React, useState } from 'react';


function App() {

  let [numPoints, updateNumPoints]= useState(3)
  let [numShapes, updateNumShapes]= useState(8)
  let [transitionTime, updateTransitionTime]= useState(3)
  let [movement, setMovement] = useState("normal")

  return (
    <div className="App">
      <div className="Controlls">
        <tr>
          <td><label>number of points:</label></td>
          <td id="value">{numPoints}</td>
          <td><button onClick={() => {updateNumPoints(numPoints + 1)}}> + </button></td>
          <td><button onClick={() => {updateNumPoints(numPoints - 1)}}> - </button></td>
        </tr>
        <tr>
         <td> <label>number of shapes:</label></td>
         <td id="value">{numShapes}</td>
         <td><button onClick={() => {updateNumShapes(numShapes + 1)}}> + </button></td>
         <td><button onClick={() => {updateNumShapes(numShapes - 1)}}> - </button></td>
        </tr>
        <tr>
         <td> <label>transition time:</label></td>
         <td id="value">{transitionTime}</td>
         <td><button onClick={() => {updateTransitionTime(transitionTime + 0.5)}}> + </button></td>
         <td><button onClick={() => {updateTransitionTime(transitionTime - 0.5)}}> - </button></td>
        </tr>
        <tr>
          <td> <label>movement:</label></td>
          <select defaultValue="normal" onInput={(event) => {setMovement(event.target.value)}}>
          <option value="none">None</option>
          <option value="normal">Normal</option>
          <option value="square">Square Root</option>
          <option value="perturbation">Perturbation</option>
          </select>
        </tr>
      </div>
      <Blobs numPoints={numPoints} numShapes={numShapes} movement={movement} transitionTime={transitionTime}/>
    </div>
  );
}

export default App;
