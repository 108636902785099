function unitVector(degrees) {
    let radians = degrees*Math.PI/180; 
  
    return [Math.cos(radians), Math.sin(radians)]
}

let genPerturbation = () => {
    let divisor = Math.random() - 0.5

    let max = 0.05

    return divisor == 0 ? 0 : max * (1 / divisor)
}

let sqrtPerturbation = () => {
    let sqare = Math.random() - 0.5

    let magnitude = 10

    if (sqare == 0) {
        return 0
    } if (sqare < 0) {
        return -Math.sqrt(-sqare) * magnitude
    } if (sqare > 0) {
        return Math.sqrt(sqare) * magnitude
    }
}

function gaussianRandom(mean=0, stdev=1) {
    const u = 1 - Math.random(); // Converting [0,1) to (0,1]
    const v = Math.random();
    const z = Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v );
    // Transform to the desired mean and standard deviation:
    return z * stdev + mean;
}

let normalPerturbation = () => {
    return gaussianRandom(0, 10)
}

export class SmoothPoint {
    constructor(x, y, degrees=Math.random() * 360, magnitude=80) {
        this.x = x
        this.y = y
        this.degrees = degrees
        this.unitVector = unitVector(degrees)
        this.magnitude = magnitude
    }

    firstControllPoint() {
        return `${this.x - this.unitVector[0] * this.magnitude},${this.y - this.unitVector[1] * this.magnitude}`
    }

    point() {
        return `${this.x},${this.y}`

    }

    secondControllPoint() {
        return `${this.x + this.unitVector[0] * this.magnitude},${this.y + this.unitVector[1] * this.magnitude}`
    }

    jiggle(movement) {

        movement = movement || "normal"

        let rand = {
            "normal": normalPerturbation,
            "none": () => 0,
            "square": sqrtPerturbation,
            "perturbation": genPerturbation
        }[movement]


        this.x += rand()
        this.y += rand()

        let clamp = (min, value, max) => {
            return Math.min(Math.max(min, value), max)
        }

        this.x = clamp(0, this.x, 100)
        this.y = clamp(0, this.y, 100)


        this.degrees -= Math.random() * 0.5
        this.unitVector = unitVector(this.degrees)
    }
    
}