import { SmoothPoint } from "./Points"
export class Shape {

    constructor(sides) {
        this.curves = Array.from({ length: sides }, this.newPoint)
        this.randomize()
    }

    set sides(value){
      while (this.curves.length < value) {
        this.curves.push(this.newPoint())
      }

      while (this.curves.length > value) {
        this.curves.pop()
      }
    }

    newPoint() {
      return new SmoothPoint(Math.random() * 100, Math.random() * 100)
    }

    randomize() {
      this.curves = this.curves.map(this.newPoint)
    }

    jiggle() {
        this.curves.forEach(curve => {
            curve.jiggle(this.movement)
        })
    }
  
    svgData() {
      let lastCurve = this.curves.at(-1)

      let data = `M ${lastCurve.point()} C ${lastCurve.secondControllPoint()} `
  
      for (let curve of this.curves ){
        data += `${curve.firstControllPoint()} ${curve.point()} `
        data += curve === lastCurve ? "" : `C ${curve.secondControllPoint()} `
      }      
      return data
    }
  }