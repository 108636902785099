import { Component } from "react";
import { Shape } from './Shapes'

export class Blobs extends Component {

    static defaultProps = {
        numShapes: 8,
        numPoints: 3,
        refreshInterval: 1,
        movement: "normal",
        transitionTime: 3
    }

    state = {
        shapes: this.makeShape(),
        moves: true
    }

    componentDidMount() {
        this.makeInterval()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.numShapes != this.props.numShapes) {
            while (this.state.shapes.length < this.props.numShapes) {
                this.state.shapes.push(new Shape(this.props.numPoints))
            }
            
            while (this.state.shapes.length > this.props.numShapes) {
                this.state.shapes.pop()
            }

            this.setState({})
        }
        if (prevProps.numPoints != this.props.numPoints) {
            this.state.shapes.forEach((shape) => shape.sides = this.props.numPoints)
            this.setState({})
        }

        if (prevProps.refreshInterval != this.props.refreshInterval){
            clearInterval(this.refreshInterval)
            this.setState({})
        }

        if (prevProps.movement != this.props.movement) {
            this.state.shapes.forEach((shape) => { shape.movement = this.props.movement })
        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval)
    }

    makeInterval() {
        this.refreshInterval = setInterval(() => {
            if (this.state.moves) {
                this.state.shapes.forEach((item) => item.jiggle())
                this.forceUpdate()
            }
        }, this.props.refreshInterval * 1234);
    }

    makeShape() {
        let shapes = []
        for (let i = 0; i < this.props.numShapes; i++){
          shapes.push(new Shape(this.props.numPoints))
        }
        return shapes
    }

    svgData() {
        return this.state.shapes.reduce((acc, value) => {return acc + value.svgData()},"")
    }

    render() {
        return (
        <svg ba style={{"--path-transition-time": this.props.transitionTime + "s", border: "solid", borderColor: this.state.moves ? "white" : "black"  }} onClick={() => {this.setState({moves: !this.state.moves})}} viewBox='-50 -50 200 200'>
            <path fillRule='evenodd' opacity="1" d={this.svgData()}></path>
        </svg>
        )
    }
}